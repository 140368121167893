import * as React from "react";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import styles from "./index.module.css";

import Hero from "../../../../components/ApartmentHero";
import Icon from "../../../../components/Icon";
import Footer from "../../../../components/Footer";

import photo1 from "../../../../../img/apartments/deluxe_apartment/1.jpg";
import photo2 from "../../../../../img/apartments/deluxe_apartment/2.jpg";
import photo3 from "../../../../../img/apartments/deluxe_apartment/3.jpg";
import photo4 from "../../../../../img/apartments/deluxe_apartment/4.jpg";
import photo5 from "../../../../../img/apartments/deluxe_apartment/5.jpg";
import photo6 from "../../../../../img/apartments/deluxe_apartment/6.jpg";
import photo7 from "../../../../../img/apartments/deluxe_apartment/7.jpg";
import photo8 from "../../../../../img/apartments/deluxe_apartment/8.jpg";
import photo9 from "../../../../../img/apartments/deluxe_apartment/9.jpg";
import photo10 from "../../../../../img/apartments/deluxe_apartment/10.jpg";

export const DeluxeApartment = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Deluxe Two-Bedroom Apartment</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Coda&family=IBM+Plex+Serif:wght@300&display=swap" />
        <script data-react-helmet="true" src="https://kit.fontawesome.com/7488f5bdb2.js" crossorigin="anonymous"></script>
      </Helmet>
      <Hero title="Deluxe Two-Bedroom Apartment" photo={photo1} />
      <div className={styles.container}>
        <p className={styles.description}>Comfortable double room with an area of 40 sq. meters with two double king-size beds (200 cm) equipped with premium memory foam mattresses ensuring comfort of rest and a sofa with a table in the living area. Moreover one of the bed can be arranged as 2 single (100 cm) beds. Stylishly finished and equipped. It is air-conditioned and situated on the ground floor. At the disposal of our guests are bottled mineral water, a coffee machine with a selection of delicious coffee pods, an electric kettle with tea bags.</p>
        <div className={styles.grid}>
          <Icon apartment icon="fa-bed" text="1 king size bed (200 cm)" />
          <Icon apartment icon="fa-chair" text="Desk with chair, 15.6 laptop size safe" />
          <Icon apartment icon="fa-coffee" text="Kettle, teas, Nespresso coffee machine" />
          <Icon apartment icon="fa-utensils" text="Tableware" />
          <Icon apartment icon="fa-tint" text="Sparkling and still mineral water" />
          <Icon apartment icon="fa-tv" text="TV with cable channels" />
          <Icon apartment icon="fa-wifi" text="Free WI-FI" />
          <Icon apartment icon="fa-shower" text="2 sets of towels, shower gel, liquid soap & hair dryer" />
        </div>
        <Carousel showArrows={true}>
                <div>
                  <img src={photo1} />
                </div>
                <div>
                  <img src={photo2} />
                </div>
                <div>
                  <img src={photo3} />
                </div>
                <div>
                  <img src={photo4} />
                </div>
                <div>
                  <img src={photo5} />
                </div>
                <div>
                  <img src={photo6} />
                </div>
                <div>
                  <img src={photo7} />
                </div>
                <div>
                  <img src={photo8} />
                </div>
                <div>
                  <img src={photo9} />
                </div>
                <div>
                  <img src={photo10} />
                </div>
        </Carousel>
        <hr />
        <p className={styles.regulations}>The rooms are cleaned on the Guest's request (No later than to 1:00 PM). Guests can borrow an iron and ironing board.</p>
        <hr />
        <p className={styles.regulations}>For the safety of our guests, they have smoke detectors. The only designated place for smoking is the patio located on the ground floor opposite the front office.</p>
        <hr />
      </div>
      <Footer />
    </>
  );
};

export default DeluxeApartment;
